import LogoSection from "../components/Header/LogoSection"
import TheNavbar from "../components/Header/TheNavbar"
import TopHeader from "../components/Header/TopHeader"

const TheHeader = () => {
    return (
        <>
            <TopHeader />
            <LogoSection />
            <TheNavbar />
        </>
    )
}

export default TheHeader
