import './App.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BrowserRouter, Switch } from 'react-router-dom';
import ProtectedRoutes from './Routes/Protected.routes';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';


function App() {

  // const sandboxClientID = "AcxQBOfihAo5j_gOOym6TIETLtFiN5CpQMwpj-UaDtdCCT70bKSfa5ToZUuCQz3f7YheodI_55FRt68U";
  const liveClientId = "AR8fqwizAqTx-ZWmWeyLJeT6sLpbN_Be1QM0NA4GN_VsLhXnUVpUl0GyO_dW5ldtON9S8i-RYPLogvlx";

  return (
    <>

      <PayPalScriptProvider options={{
        "client-id": liveClientId,
        currency: "USD"
      }}>
        <BrowserRouter>
          <Switch>
            <ProtectedRoutes exact path="/" />
            <ProtectedRoutes exact path="/about" />
            <ProtectedRoutes exact path="/service/forex-pack" />
            <ProtectedRoutes exact path="/service/comex-pack" />
            <ProtectedRoutes exact path="/service/indices-pack" />
            <ProtectedRoutes exact path="/service/crypto-pack" />
            <ProtectedRoutes exact path="/service/courses" />
            <ProtectedRoutes exact path="/contact-us" />
            <ProtectedRoutes exact path="/offer" />
            <ProtectedRoutes exact path="/career" />
            <ProtectedRoutes exact path="/news" />
            <ProtectedRoutes exact path="/payments" />
            <ProtectedRoutes exact path="/term-and-condition" />
            <ProtectedRoutes exact path="/refund-policy" />
            <ProtectedRoutes exact path="/disclaimer" />
            <ProtectedRoutes exact path="/privacy-policy" />
            <ProtectedRoutes exact path="/quick-pay" />
            <ProtectedRoutes exact path="/career/business-analyst" />
            <ProtectedRoutes exact path="/career/digital-marketing-executive" />
            <ProtectedRoutes exact path="/career/team-leader" />
          </Switch>

        </BrowserRouter>
      </PayPalScriptProvider>
    </>
  );
}

export default App;
