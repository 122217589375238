import { ArrowRightAlt } from '@mui/icons-material'
import { Collapse } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import PaypalBtn from '../components/PaypalBtn'

const style = makeStyles({
    container: {
        width: 400,
        margin: "2em auto",
        border: "1px solid grey",
        padding: "2em 1em",
        borderRadius: 5
    },
    heading: {
        margin: 0,
        fontSize: 24
    },
    labelBlock: {
        border: "1px solid grey",
        borderRadius: 5,
        margin: "1em 0em",
        padding: "2px 10px"
    },
    label: {
        fontSize: 12,
        fontWeight: 700,
        color: "grey"
    },
    value: {
        fontSize: 16,
        // fontWeight: 600,
        color: "grey"
    },
    input: {
        border: "none",
        fontSize: 16,
        padding: "2px 10px",
        "&:focus-visible": {
            outline: "none"
        }
    },
    pricingBlock: {
        display: 'flex',
        justifyContent: "space-between"
    },
    priceInput: {
        display: "flex",
        flex: 1,
        marginRight: 10,
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid grey",
        borderRadius: 5,
        margin: "1em 0em",
        // padding: "2px 10px"
    },
    currency: {
        fontSize: 16,
        borderLeft: "1px solid grey",
        color: "grey",
        height: "100%",
        display: "flex",
        alignItems: "center",
        padding: "2px 10px"
    },
    proccedPayBtn: {
        background: "#347ab6",
        width: "100%",
        padding: "5px",
        borderRadius: "50px",
        textAlign: "center",
        fontSize: 20,
        color: "#fff",
        fontWeight: 500,
        marginTop: 10
    },
    arrow: {
        fontSize: "33px !important",
        verticalAlign: "middle"
    },
})

const QuickPay = () => {
    const classes = style()
    const [amount, setAmount] = useState("0")
    const productPaypal = {
        description: "Service Payment",
        price: Number(amount),
    }
    const [first, setFirst] = useState(false)
    const [proccedBtn, setProccedBtn] = useState(false)

    return (
        <>
            <div className={classes.container}>
                <h1 className={classes.heading}>Purchase Details</h1>

                <div className={classes.labelBlock}>
                    <span className={classes.label}>Description</span>
                    <br />
                    <span className={classes.value}>Service Payment</span>
                </div>
                <div style={{ display: "flex" }} className={classes.pricingBlock} >
                    <div className={classes.priceInput}>
                        <input
                            onChange={(e: any) => setAmount(e.target.value)}
                            className={classes.input}
                            onFocus={() => {
                                setFirst(false)
                                setProccedBtn(false)
                            }}
                            onBlur={() => setFirst(true)}
                            type="number"
                            placeholder='Price Per item'
                        />
                        <span className={classes.currency}>USD</span>
                    </div>
                    <div className={classes.labelBlock} style={{ flex: 0.6 }}>
                        <span className={classes.label}>Quantity</span>
                        <br />
                        <span className={classes.value} >1</span>
                    </div>
                </div>
                <div>
                    <div
                        onClick={() => setProccedBtn(true)}
                        className={classes.proccedPayBtn}>
                        Procced To Pay
                        <ArrowRightAlt className={classes.arrow} />
                    </div>
                    <Collapse in={proccedBtn} >
                        {(first && productPaypal.price > 0) &&
                            <PaypalBtn product={productPaypal} />
                        }
                    </Collapse>
                </div>

            </div>
        </>
    )
}

export default QuickPay