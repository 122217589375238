import { makeStyles } from "@mui/styles";

const CareerPageStyle = makeStyles({
    container: {
        padding: "2em 0em"
    },
    content: {

    },
    row: {
        width: "70%",
        margin: "auto",
        display: "flex",
        justifyContent: "space-between",
        "@media(max-width:1050px)": {
            width: "90% !important",
        },
        "@media(max-width:790px)": {
            width: "100% ",
            margin: "auto",
            flexDirection: "column"
        },
    },
    formCol: {
        width: "25%",
        paddingTop: "4em",
        "@media(max-width:1050px)": {
            width: "30% !important",
        },
    },
    infoCol: {
        width: "100%",
        "@media(max-width:1050px)": {
            width: "65% !important",
        },
    },
    col: {
        "@media(max-width:790px)": {
            width: "100% !important",
        },
    },
    formheading: {
        color: "#2d2d2d",
        fontWeight: 400,
        marginBotton: "27px",
        position: "relative",
        fontSize: "20px",
        borderBottom: "1px solid #8b8b8b",
        paddingBottom: "18px",
        "&::before": {
            content: "' '",
            width: "100px",
            height: "4px",
            backgroundColor: "#347ab6",
            position: "absolute",
            left: "0px",
            bottom: "-2px",

        }
    },
    heading: {
        color: "#2d2d2d",
        fontWeight: 800,
        marginBotton: "27px",
        position: "relative",
        fontSize: "24px",
        borderBottom: "1px solid #8b8b8b",
        paddingBottom: "18px",
        "&::before": {
            content: "' '",
            width: "150px",
            height: "4px",
            backgroundColor: "#347ab6",
            position: "absolute",
            left: "0px",
            bottom: "-2px",

        }
    },
    jobDescrictionSection: {
        backgroundColor: "#E7E7E7",
        padding: "10px 15px",
        borderLeft: "4px solid #367DBA"
    },
    jobTitle: {
        margin: "0px",
        paddingTop: "20px",
        paddingBottom: "10px",
        fontSize: "24px",
        fontWeight: 500,
        color: "#474747"
    },
    jobDescriction: {
        margin: "0px",
        paddingBottom: "10px",
        fontSize: "15px",
        fontWeight: 100,
    },
    accountabilitySection: {
        paddingTop: "2em"
    },
    accountabilityTitle: {
        fontSize: "18px",
        margin: "10px 0px",
        fontWeight: 500,
    },
    accountabilityDescription: {
        fontSize: "15px",
        color: "#333",
        margin: "25px 0px"
    },
    menuListSection: {
        paddingTop: "2em"
    },
    menu: {
        listStyle: "none",
        paddingLeft: "18px",
        margin: "0px",
    },
    item: {
        margin: "0px",
        padding: "5px 0px",
        color: "#333",
        fontWeight: 500,
        fontSize: "15px",
        display: "flex",
        alignItems: "center",
    },
    arrowIcon: {
        fontSize: "14px !important",
        marginRight: "5px",
        verticalAlign: "middle",
        display: "none",
        color: "#347ab6",
        fontWeight: 800
    },
    arrowIconImg: {
        width: "8px",
        marginRight: "5px",
    },
    requiredment: {
        fontSize: "18px",
        fontWeight: 500,
        marginTop: "10px",
        marginBottom: "50px"
    },
    ageCriteria: {
        fontSize: "18px",
        margin: "10px 0px",
        fontWeight: 500,
        color: "#333"
    },
    moreDetails: {
        fontSize: "15px",
        fontWeight: "bold",
        color: "#333"
    },
    contactForm: {
        border: "1px solid #ddd",
        // borderRight: "0px",
        padding: "3.5em 2em",
        margin: "3em 0em",
        borderRadius: "5px",
        boxShadow: "0 1px 2px rgba(0,0,0,.05)",
        width: "100%",
    },
    underLine: {
        borderBottom: "1px solid #ddd",
        margin: "20px 0px"
    }
})

export default CareerPageStyle