import { lazy } from 'react';
import TermsAndCondition from './Pages/PolicyPages/TermsAndCondition';
import DisclaimerPolicy from './Pages/PolicyPages/DisclaimerPolicy';
import PrivacyPolicy from './Pages/PolicyPages/PrivacyPolicy';
import RefundPolicy from './Pages/PolicyPages/RefundPolicy';
import QuickPay from './Pages/QuickPay';
import BusinessAnalyst from './components/Career/BusinessAnalyst';
import DigitalMarketingExecutive from './components/Career/DigitalMarketingExecutive';
import TeamLeader from './components/Career/TeamLeader';
const Home = lazy(() => import('./Pages/Home'));
const About = lazy(() => import('./Pages/About'));
const Career = lazy(() => import('./Pages/Career'));
const ContactUs = lazy(() => import('./Pages/ContactUs'));
const News = lazy(() => import('./Pages/News'));
const Offer = lazy(() => import('./Pages/Offer'));
const ComexPack = lazy(() => import('./Pages/ServicesPages/ComexPack'));
const Courses = lazy(() => import('./Pages/ServicesPages/Courses'));
const CryptoPack = lazy(() => import('./Pages/ServicesPages/CryptoPack'));
const ForexPack = lazy(() => import('./Pages/ServicesPages/ForexPack'));
const IndicesPack = lazy(() => import('./Pages/ServicesPages/IndicesPack'));

const routes = [
    { path: "/", name: "Free Classes", component: Home },
    { path: "/about", name: "about", component: About },
    { path: "/service/forex-pack", name: "forexpack", component: ForexPack },
    { path: "/service/comex-pack", name: "comexpack", component: ComexPack },
    { path: "/service/indices-pack", name: "indicespack", component: IndicesPack },
    { path: "/service/crypto-pack", name: "cryptopack", component: CryptoPack },
    { path: "/service/courses", name: "courses", component: Courses },
    { path: "/contact-us", name: "contactus", component: ContactUs },
    { path: "/offer", name: "offer", component: Offer },
    { path: "/career", name: "career", component: Career },
    { path: "/news", name: "news", component: News },
    { path: "/term-and-condition", name: "termAndConditio", component: TermsAndCondition },
    { path: "/refund-policy", name: "Refund Policy", component: RefundPolicy },
    { path: "/disclaimer", name: "Disclaimer Policy", component: DisclaimerPolicy },
    { path: "/privacy-policy", name: "Privacy Policy", component: PrivacyPolicy },
    { path: "/quick-pay", name: "Quick Pay", component: QuickPay },
    { path: "/career/business-analyst", name: "business analyst", component: BusinessAnalyst },
    { path: "/career/digital-marketing-executive", name: "digital marketing executive", component: DigitalMarketingExecutive },
    { path: "/career/team-leader", name: "team leader", component: TeamLeader },
]

export default routes