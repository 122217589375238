import CareerForm from './CareerForm'
import arrowImg from "../../assest/img/list_icon.webp"
import careerpageStyle from "./CareerPageStyle";
import DynamicCareerBanner from './DynamicCareerBanner';
import banner from "../../assest/img/business_analyst_slider.webp"
import banner_moblie from "../../assest/img/business-analyst-mob-slider.webp"





const BusinessAnalyst = () => {
    const classes = careerpageStyle()
    return (
        <>
            <DynamicCareerBanner
                img={banner}
                responsiveimg={banner_moblie}
                title="Current Openings - Business Analyst"
            />
            <div className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.row}>
                        <div className={`${classes.col} ${classes.infoCol}`}>
                            {/* <div>
                                <h4 className={classes.formheading} >Apply For This Position</h4>
                                <CareerForm />
                            </div> */}
                            <h4 className={classes.heading} >Position Description</h4>
                            <div className={classes.jobDescrictionSection}>

                                <h3 className={classes.jobTitle}>
                                    Job Title:
                                </h3>
                                <p className={classes.jobDescriction}>
                                    Business Analyst (International Process)
                                </p>
                                <h3 className={classes.jobTitle}>
                                    Reporting to:
                                </h3>
                                <p className={classes.jobDescriction}>
                                    Relationship Manager (Sales)
                                </p>

                            </div>
                            <div className={classes.accountabilitySection}>
                                <h4 className={classes.accountabilityTitle}>Accountability:</h4>
                                <p className={classes.accountabilityDescription} >Client Support/Acquisition, Client Relationship management.</p>
                            </div>
                            <div className={classes.menuListSection}>
                                <h4 className={classes.heading} >Roles and Responsibilities:</h4>
                                <ul className={classes.menu}>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Analyse FOREX & COMEX Market & investment tips.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Giving Support of Forex / Comex Technical Services to overseas clients.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Generating prospects & client relationship building.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Grievance handling &
                                    </li>
                                </ul>
                            </div>

                            <div className={classes.menuListSection}>
                                <h4 className={classes.heading} >Required Competencies:</h4>
                                <ul className={classes.menu}>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Fluent English Communication skills.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Excellent analytical skills.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Good Convincing & Negotiation Skills.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Adaptable in dynamic and fast paced work environment
                                    </li>
                                </ul>
                            </div>

                            <div className={classes.menuListSection}>
                                <h4 className={classes.heading} >Required Qualifications</h4>
                                <p className={classes.requiredment}>Candidate Must be Post Graduate with minimum 2 year of work experience</p>
                                <h4 className={classes.ageCriteria}>
                                    <strong>Age Criteria:</strong> 18 – 32 Years
                                </h4>
                                <p className={classes.moreDetails}>For More Details Contact us at info@investmentbaron.in</p>
                            </div>

                            <div className={classes.contactForm}>
                                {/* <div className={classes.underLine}></div> */}
                                <CareerForm />
                            </div>
                            <div className={classes.underLine}></div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default BusinessAnalyst