import { makeStyles } from "@mui/styles";
import { Link, useLocation } from "react-router-dom";
const Style = makeStyles({
  main: {
    position: "relative",
  },
  img: {
    width: "100%",
    "@media(max-width:600px)": {
      display: "none !important",
    },
  },
  responsiveimg: {
    width: "100%",
    display: "none",
    "@media(max-width:600px)": {
      display: "block !important",
    },
  },
  textsection: {
    position: "absolute",
    bottom: "20%",
    left: "10%",
    color: "white",
    width: "65%",
    "@media(max-width:600px)": {
      width: "100%",
      left: "0px",
      paddingLeft: "10%",
      // bottom: "auto",
      // left: "auto",
      // top: "50%",
      // right: "50%",
      // textAlign: "center",
      // transform: "translate(50%,-50%)",
    },
  },
  heading: {
    margin: "0px",
    fontSize: "2em",
    fontWeight: 600,
    paddingBottom: "10px",
    "@media(max-width:600px)": {
      fontSize: "1.2em",
    },
  },
  descripction: {
    fontSize: "0.9em",
    margin: "0px",
    fontWeight: 400,
    "@media(max-width:600px)": {
      fontSize: "0.7em",
    },
  },
  links: {
    display: "inline-block",
    color: "#fff",
    fontWeight: 400,
    textTransform: "capitalize",
    textDecoration: "underLine",
  },
  forward_icon: {
    display: "inline-block",
    padding: "0px 10px",
  },
});

const DynamicCareerBanner = (props: DynamicHeroBanner) => {
  const { title, img, responsiveimg } = props;
  const classes = Style();

  const location = useLocation();
  const currentPath = location.pathname;

  const PathArray = currentPath.split("/");

  return (
    <>
      <div className={classes.main}>
        <img src={img} alt="about" className={classes.img} />
        <img
          src={responsiveimg}
          alt="about"
          className={classes.responsiveimg}
        />
        <div className={classes.textsection}>
          <h1 className={classes.heading}>{title}</h1>
          <p className={classes.descripction}>
            <Link className={classes.links} to="/">
              Home
            </Link>
            <span className={classes.forward_icon}>{">>"}</span>
            {/* <Link className={classes.links} to={home_link}>{home_text}</Link> */}

            <Link className={classes.links} to={"/career"}>
              {PathArray[1]}
            </Link>

            {PathArray.length === 3 && (
              <>
                <span className={classes.forward_icon}>{">>"}</span>
                <div className={classes.links}>
                  {PathArray[2].replaceAll("-", " ")}
                </div>
              </>
            )}

            {/* <Link className={classes.links} to={career_link}>{career_text}</Link> */}
          </p>
        </div>
      </div>
    </>
  );
};

export default DynamicCareerBanner;
