import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import logo from '../../assest/img/logo-2.webp'

const Style = makeStyles({
    container: {
        background: "#fff",
        padding: " 20px 5px"
    },
    row: {
        width: "90%",
        margin: "auto",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
    },
    col: {
        overflow: "hidden"
    },
    btn: {
        color: "#347ab6",
        backgroundColor: "transparent",
        border: "1px solid #347ab6",
        padding: "7px 14px",
        borderRadius: "5px",
        fontSize: "16px",
        fontWeight: 600,
        cursor: "pointer",
        float: "right",
        textDecoration: "none",
        "&:hover": {
            color: "white",
            backgroundColor: "#347ab6",
        },
    }
})

const LogoSection = () => {
    const classes = Style()
    return (
        <>
            <div className={classes.container}>
                <div className={classes.row}>
                    <div className={classes.col}>
                        <Link to="/">
                            <img src={logo} width={"200px"} alt="Logo" />
                        </Link>
                    </div>
                    <div className={classes.col}>
                        <Link to="/quick-pay" className={classes.btn}>
                            Quick pay
                        </Link>
                    </div>

                </div>

            </div>

        </>
    )
};

export default LogoSection;
