import {
  Email,
  Facebook,
  Instagram,
  LocationOn,
  Telegram,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import logo from "../assest/img/logo-2-white.webp";
import YouTube from "@mui/icons-material/YouTube";
const Style = makeStyles({
  container: {
    backgroundColor: "#3c403d",
    padding: "40px 10px 5px 10px",
    marginTop: "2em",
    "@media(max-width:720px)": {
      marginTop: "2em",
      paddingTop: "0px",
    },
  },
  row: {
    width: "90%",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    padding: "20px 0px",
    "@media(max-width:1150px)": {
      justifyContent: "initial",
    },
  },
  col: {
    width: "20%",
    "@media(max-width:1150px)": {
      width: "33%",
      margin: "20px 0px",
    },
    "@media(max-width:700px)": {
      width: "50%",
    },
    "@media(max-width:600px)": {
      width: "100%",
    },
  },
  colMobile: {
    "@media(max-width:600px)": {
      width: "50%",
    },
  },
  logo: {
    width: "150px",
    display: "block",
    // paddingBottom: "10px"
  },
  text: {
    fontSize: "14px",
    margin: "10px 0px",
    color: "#fff",
    fontWeight: 600,
    paddingRight: "20px",
  },
  heading: {
    margin: "0px",
    color: "#fff",
    fontWeight: 700,
    paddingBottom: "10px",
  },
  menu: {
    paddingLeft: "0px",
    margin: "0px",
    listStyle: "none",
  },
  item: {
    display: "inline-block",
    textDecoration: "none",
    color: "#fff",
    fontSize: "15px",
    fontWeight: 600,
    margin: "5px 0px",
  },
  contactRow: {
    display: "flex",
    alignItems: "center",
  },
  contactCol: {
    margin: "5px 2px",
  },
  contactText: {
    fontSize: "14px",
    paddingLeft: "10px",
    display: "inline-block",
    color: "#fff",
    textDecoration: "none",
    cursor: "pointer",
  },
  icon: {
    verticalAlign: "middle",
    color: "#fff",
    fontSize: "1.7em !important",
  },
  whatsappicon: {
    verticalAlign: "middle",
    color: "#fff",
    fontSize: "1.7em !important",
    "&:hover": {
      color: "#0088cc",
    },
  },
  copyrightbox: {
    borderTop: "1px solid white",
    marginTop: "2em",
    width: "90%",
    margin: "auto",
  },
  copyrighttext: {
    color: "white",
    textAlign: "center",
    paddingBottom: "0px",
    marginTop: "1em",
    fontSize: "14px",
  },
  lgScreenSocialMedia: {
    marginTop: "20px",
    marginLeft: "5px",
    "@media(max-width:600px)": {
      display: "none",
    },
  },
  mobileDeviceSocial: {
    display: "none",
    "@media(max-width:600px)": {
      display: "block",
      margin: "0px 0px",
    },
  },
  socialMediaMenu: {
    display: "flex",
  },
  socialMediaItem: {
    display: "inline-block",
    paddingRight: "10px",
  },
  msRefLink: {
    textDecoration: "none",
    color: "#fff",
  },
  linkicon: {
    fontSize: "1.1em !important",
    color: "#fff",
    verticalAlign: "middle",
  },
  circlelink: {
    borderRadius: "50%",
    width: "31px !important",
    height: "31px !important",
    border: "2px solid #fff",
    textAlign: "center",
    margin: "0px auto",
    paddingTop: "1px",
    "&:hover": {
      backgroundColor: "#3B5998",
      color: "#fff",
      border: "1px solid #3B5998",
      boxShadow: "inset 0px 0px 0px 3px #ffffff",
    },
  },
  facebookcolor: {
    "&:hover": {
      backgroundColor: "#3B5998",
      color: "#fff",
      border: "1px solid #3B5998",
      boxShadow: "inset 0px 0px 0px 3px #ffffff",
    },
  },
  twittercolor: {
    "&:hover": {
      backgroundColor: "#33ccff",
      color: "#fff",
      border: "1px solid #33ccff",
      boxShadow: "inset 0px 0px 0px 3px #ffffff",
    },
  },
  youtubecolor: {
    "&:hover": {
      backgroundColor: "red",
      color: "#fff",
      border: "1px solid red",
      boxShadow: "inset 0px 0px 0px 3px #ffffff",
    },
  },
  instagramcolor: {
    "&:hover": {
      backgroundColor: "#cd3b9e",
      color: "#fff",
      border: "1px solid #cd3b9e",
      boxShadow: "inset 0px 0px 0px 3px #ffffff",
    },
  },
});
const TheFooter = () => {
  const classes = Style();

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.col}>
          <Link
            onClick={() => window.scrollTo(0, 0)}
            to="/"
            className={classes.logo}
          >
            <img src={logo} width="100%" alt="" />
          </Link>
          <div>
            <p className={classes.text}>
              We are in this business from several years providing high accuracy
              research .
            </p>
          </div>
        </div>

        <div className={`${classes.col} ${classes.colMobile}`}>
          <h3 className={classes.heading}>Our Services</h3>
          <ul className={classes.menu}>
            <li>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/service/forex-pack"
                className={classes.item}
              >
                Forex pack
              </Link>
            </li>
            <li>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/service/comex-pack"
                className={classes.item}
              >
                Comex pack
              </Link>
            </li>
            <li>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/service/indices-pack"
                className={classes.item}
              >
                Indices pack
              </Link>
            </li>
            <li>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/service/crypto-pack"
                className={classes.item}
              >
                Crypto pack
              </Link>
            </li>
            <li>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/service/courses"
                className={classes.item}
              >
                Courses
              </Link>
            </li>
          </ul>
        </div>

        <div className={`${classes.col} ${classes.colMobile}`}>
          <h3 className={classes.heading}>Quick Links</h3>
          <ul className={classes.menu}>
            <li>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/about"
                className={classes.item}
              >
                About
              </Link>
            </li>
            <li>
              <a
                href="https://blog.investmentbaron.in/"
                target="_blank"
                rel="noreferrer"
                className={classes.item}
              >
                Blog
              </a>
            </li>
            <li>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/service/courses"
                className={classes.item}
              >
                Courses
              </Link>
            </li>
            <li>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="offer"
                className={classes.item}
              >
                Get offer
              </Link>
            </li>
            <li>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/career"
                className={classes.item}
              >
                Career
              </Link>
            </li>
            <li>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/news"
                className={classes.item}
              >
                News
              </Link>
            </li>
          </ul>
        </div>
        <div className={classes.col}>
          <h3 className={classes.heading}>Our Policy</h3>
          <ul className={classes.menu}>
            <li>
              <Link
                to="/term-and-condition"
                onClick={() => window.scrollTo(0, 0)}
                className={classes.item}
              >
                Terms and condition
              </Link>
            </li>
            <li>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/refund-policy"
                className={classes.item}
              >
                Refund policy
              </Link>
            </li>
            <li>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/disclaimer"
                className={classes.item}
              >
                Disclaimer
              </Link>
            </li>
            <li>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                to="/privacy-policy"
                className={classes.item}
              >
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
        <div className={classes.col}>
          <h3 className={classes.heading}>Contact Us</h3>
          <ul className={classes.menu}>
            {/* <div className={classes.contactRow}>
              <div className={classes.contactCol}>
                <LocationOn className={classes.icon} />
              </div>
              <div className={classes.contactCol}>
                <span className={classes.contactText}>
                  204 Twin Star Building MP
                </span>
              </div>
            </div> */}
            {/* <div className={classes.contactRow}>
              <div className={classes.contactCol}>
                <Telegram className={classes.whatsappicon} />
              </div>
              <div className={classes.contactCol}>
                <a
                  href="https://t.me/investmentbaron "
                  target={"_blank"}
                  rel="noreferrer"
                  className={classes.contactText}
                >
                  investmentbaron
                </a>
              </div>
            </div> */}
            <div className={classes.contactRow}>
              <div className={classes.contactCol}>
                <Email className={classes.icon} />
              </div>
              <div className={classes.contactCol}>
                <a
                  href="mailto:info@investment-baron.com"
                  className={classes.contactText}
                >
                  info@investment-baron.com
                </a>
              </div>
            </div>
          </ul>
          <div className={classes.lgScreenSocialMedia}>
            <ul className={`${classes.menu} ${classes.socialMediaMenu}`}>
              <li>
                <a
                  className={classes.socialMediaItem}
                  href="https://www.facebook.com/investmentbaron9/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className={`${classes.circlelink} ${classes.facebookcolor}`}
                  >
                    <Facebook className={classes.linkicon} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  className={classes.socialMediaItem}
                  href="https://twitter.com/investmentbaron"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className={`${classes.circlelink} ${classes.twittercolor}`}
                  >
                    <Twitter className={classes.linkicon} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  className={classes.socialMediaItem}
                  href=" https://www.youtube.com/channel/UC-94FwN44ZLhHQslvEHxT_Q"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className={`${classes.circlelink} ${classes.youtubecolor}`}
                  >
                    <YouTube className={classes.linkicon} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  className={classes.socialMediaItem}
                  href=" https://www.instagram.com/investment_baron/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className={`${classes.circlelink} ${classes.instagramcolor}`}
                  >
                    <Instagram className={classes.linkicon} />
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className={`${classes.col} ${classes.mobileDeviceSocial}`}>
          <ul className={`${classes.menu} ${classes.socialMediaMenu}`}>
            <li>
              <a
                className={classes.socialMediaItem}
                href="https://www.facebook.com/investmentbaron9/"
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className={`${classes.circlelink} ${classes.facebookcolor}`}
                >
                  <Facebook className={classes.linkicon} />
                </div>
              </a>
            </li>
            <li>
              <a
                className={classes.socialMediaItem}
                href="https://twitter.com/investmentbaron"
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className={`${classes.circlelink} ${classes.twittercolor}`}
                >
                  <Twitter className={classes.linkicon} />
                </div>
              </a>
            </li>
            <li>
              <a
                className={classes.socialMediaItem}
                href=" https://www.youtube.com/channel/UC-94FwN44ZLhHQslvEHxT_Q"
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className={`${classes.circlelink} ${classes.youtubecolor}`}
                >
                  <YouTube className={classes.linkicon} />
                </div>
              </a>
            </li>
            <li>
              <a
                className={classes.socialMediaItem}
                href=" https://www.instagram.com/investment_baron/"
                target="_blank"
                rel="noreferrer"
              >
                <div
                  className={`${classes.circlelink} ${classes.instagramcolor}`}
                >
                  <Instagram className={classes.linkicon} />
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={classes.copyrightbox}>
        {/* <p className={classes.copyrighttext}>© All Rights Reserved | Design by Investmentbaron </p> */}
        <p className={classes.copyrighttext}>
          2022 - Investmentbaron.All Rights Reserved | Designed by
          <a
            className={classes.msRefLink}
            target="_blank"
            href="https://www.meerasolution.com/"
            rel="noreferrer"
          >
            {" "}
            Meera Solution
          </a>
        </p>
      </div>
    </div>
  );
};

export default TheFooter;
