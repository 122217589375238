import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";


const Style = makeStyles({
    inputFieldForm: {
        "& .MuiOutlinedInput - notchedOutline": {
            border: " 0px !important",

        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "0.5px solid #000 !important",
            boxShadow: "inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6) !important"
        },
        "& .MuiInputBase-input": {
            padding: " 8px 12px !important",
            border: "0px !important",
            "&::placeholder": {
                opacity: "unset !important",
            }
        },
        "& .MuiInputBase-root": {
            backgroundColor: "#fff !important",
            borderRadius: "5px!important",
            color: "#999 !important",
            fontSize: "14px !important",
        },
        // "& .MuiInputBase-input-MuiOutlinedInput-input::placeholder":{

        // }
    }
})

interface typeInputField {
    name: string;
    placeholder?: string;
    label?: string;
    required?: boolean;
    handleChange?: any
    errors?: any
    touched?: any
    values?: any

}

const InputField = (props: typeInputField) => {
    const classes = Style()
    const { placeholder, name, handleChange, errors, touched, values } = props;
    return (
        <>
            <div >

                <TextField
                    className={classes.inputFieldForm}
                    fullWidth
                    variant="outlined"
                    type="text"
                    name={name}
                    placeholder={placeholder}
                    required={false}
                    value={values[`${name}`]}
                    onChange={handleChange}
                    error={touched[`${name}`] && Boolean(errors[`${name}`])}
                    helperText={touched[`${name}`] && errors[`${name}`]}

                />

            </div>
        </>
    );
};

export default InputField;
