import CareerForm from './CareerForm'
import arrowImg from "../../assest/img/list_icon.webp"
import careerpageStyle from "./CareerPageStyle";
import DynamicCareerBanner from './DynamicCareerBanner';
import banner from "../../assest/img/business_analyst_slider.webp"
import banner_moblie from "../../assest/img/business-analyst-mob-slider.webp"


const DigitalMarketingExecutive = () => {
    const classes = careerpageStyle()
    return (
        <>
            <DynamicCareerBanner
                img={banner}
                responsiveimg={banner_moblie}
                title="Current Openings - Digital Marketing Executive"
            />
            <div className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.row}>
                        {/* <div className={`${classes.col} ${classes.formCol}`}>
                            <h4 className={classes.formheading} >Apply For This Position</h4>
                            <CareerForm />
                        </div> */}
                        <div className={`${classes.col} ${classes.infoCol}`}>
                            <h4 className={classes.heading} >Position Description</h4>
                            <div className={classes.jobDescrictionSection}>

                                <h3 className={classes.jobTitle}>
                                    Job Title:
                                </h3>
                                <p className={classes.jobDescriction}>
                                    E-Marketing Executive
                                </p>
                                <h3 className={classes.jobTitle}>
                                    Reporting to:
                                </h3>
                                <p className={classes.jobDescriction}>
                                    Team Leader (E-Marketing)
                                </p>

                            </div>
                            <div className={classes.accountabilitySection}>
                                <h4 className={classes.accountabilityTitle}>Accountability:</h4>
                                <p className={classes.accountabilityDescription} >Digital marketing activities, generating traffic over website, Social Media Marketing programs for web trafficking</p>
                            </div>
                            <div className={classes.menuListSection}>
                                <h4 className={classes.heading} >Duties & Responsibilities:</h4>
                                <ul className={classes.menu}>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Online branding, Social Media Optimization.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Lead generation, Social Media Marketing etc.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Enhance digital marketing performance every day.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Social Media Promotions & Client Chat etc.
                                    </li>
                                </ul>
                            </div>

                            <div className={classes.menuListSection}>
                                <h4 className={classes.heading} >Required Competencies:</h4>
                                <ul className={classes.menu}>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Excellent Communication & Interpersonal Skills
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Passion for Digital Marketing.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Knowledge of international market preferred
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Creative thinking & Client convincing.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Project management and time management skills
                                    </li>
                                </ul>
                            </div>

                            <div className={classes.menuListSection}>
                                <h4 className={classes.heading} >Required Qualifications</h4>
                                <p className={classes.requiredment}>
                                    Candidate Must be Graduate at least,Pursuing Candidate need not to apply.
                                </p>
                                <h4 className={classes.ageCriteria}>
                                    <strong>Age Criteria:</strong> 18 – 30 Years
                                </h4>
                                <p className={classes.moreDetails}>For More Details Contact us at info@investmentbaron.in</p>
                            </div>

                            <div className={classes.contactForm}>
                                {/* <div className={classes.underLine}></div> */}
                                <CareerForm />
                            </div>
                            <div className={classes.underLine}></div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default DigitalMarketingExecutive