import { ArrowForwardIos } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

const style = makeStyles({
    container: {
        padding: "2em 0em",
        width: "90%",
        margin: "auto",
        "& h2": {
            fontSize: "2.3em",
            margin: "0px",
            paddingBottom: "10px",
            color: "#347ab6",
            fontWeight: 600,
            textAlign: "center",
        },
        "& h4": {
            margin: "0px",
            padding: "10px 0px",
            fontWeight: 600,
            fontSize: "1.5em",
        },
        "& ul": {
            listStyle: "none",
        },
        "& li": {
            color: "#5f5f5f",
            fontSize: "16px",
            padding: "3px 0px",
            textAlign: "justify"
        },
        "& p": {
            margin: "0px",
            paddingBottom: "10px",
            fontSize: "16px",
            color: "#5f5f5f",
            fontWeight: 600,
        },
        "& span": {
            color: "#646464",
            fontWeight: 700
        }
    },
    arrowIcon: {
        fontSize: "14px !important",
        marginRight: "5px",
        verticalAlign: "middle",
        display: "none",
        color: "#000"
    },
})

const PrivacyPolicy = () => {
    const classes = style()
    return (
        <>
            <div className={classes.container}>
                <h2>PRIVACY POLICY</h2>
                <p><span>INVESTMENT BARON</span> recognizes that your privacy is important. We are committed to maintaining the highest standards of decency, fairness, and integrity in all our operations. We promise you that we shall remain loyal and dedicated to protecting our client’s privacy.</p>
                <p>We at <span>INVESTMENT BARON</span> understand the confidentiality of your information and maintain it with security.</p>
                <p >We understand that the information with us is for privacy and confidential and we are eager in keeping up this promise that we will safeguard the information of our clients in any situation. The information like your name, mobile number, email address, address etc are required for the company so that client can be helped with better communication of the services. Following policy has been implemented for safeguarding the information.</p>
                <ul>
                    <li> <ArrowForwardIos className={classes.arrowIcon} />If you register yourself on www.investmentbaron.in/, it means that you are authorizing us to use your number to send SMS and can call you whenever needed which is in accordance with our privacy policy. In case there is any change in your contact info you would let us know about this prior through any mode you like.</li>
                    <li> <ArrowForwardIos className={classes.arrowIcon} />We may invite you for polls and contest or feedback posted on our website or on your mail, replying to such emails or participating in such activities is voluntary no obligations.</li>
                    <li> <ArrowForwardIos className={classes.arrowIcon} />The personal information would not be rented, sold, exchanged given, or transferred to any person or company without your consent or reason.</li>
                    <li> <ArrowForwardIos className={classes.arrowIcon} />We have physical, electronic, and procedural safeguards to protect personal information about you and your account information is password-protected.</li>
                    <li> <ArrowForwardIos className={classes.arrowIcon} />The information provided by you would be used only for rendering the services for which you have subscribed to us and not for any other purpose.</li>
                    <li> <ArrowForwardIos className={classes.arrowIcon} />Personal info like name, mobile number, email address, address, etc may be used to introduce new products and services or newsletters, new packages, events, or any other services started by the company for enhancement of our relationship.</li>
                    <li> <ArrowForwardIos className={classes.arrowIcon} />Once you provide us with the information we assume that you agree with the terms and conditions and even with the privacy policy of the company.</li>
                </ul>
            </div>
        </>
    )
}

export default PrivacyPolicy