import { Route } from "react-router-dom"
import TheLayout from "../Container/TheLayout"




const ProtectedRoutes = (props: any, { ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return <TheLayout {...props} />
            }}
        />
    )
}

export default ProtectedRoutes
