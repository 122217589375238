import { Menu } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logoImg from "../../assest/img/logo-2-white.webp";
import serviceArrow from "../../assest/img/serviceArrow.webp";

const TheNavbar = () => {
  const [show, setshow] = useState(false);
  const handleClose = () => setshow(!show);

  const Style = makeStyles({
    container: {
      backgroundColor: "#347ab6",
      position: "sticky",
      zIndex: "200",
      top: "-1px",
      right: 0,
      left: 0,
      "@media(max-width:850px)": {
        padding: "10px 10px",
      },
    },
    navbar: {
      width: "85%",
      margin: "auto",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "@media(max-width:850px)": {
        display: "block",
      },
    },
    logoSection: {
      width: "170px",
      display: "none",
    },
    logoSectionResponsive: {
      marginRight: "8em",
      display: "block",
      "@media(max-width:850px)": {},
    },
    logo: {
      textDecoration: "none",
      color: "#3c403d",
      fontWeight: 700,
      fontSize: "1.4em",
      display: "inline-block",
      width: 145,
    },
    nav: {
      "@media(max-width:850px)": {
        display: show ? "block" : "none",
      },
    },
    menu: {
      padding: "0px",
      margin: "0px",
      listStyle: "none",
      display: "flex",
      "@media(max-width:850px)": {
        display: "block",
        overflow: "hidden",
      },
    },
    item: {
      display: "inline-block",
      textDecoration: "none",
      margin: "0px 5px",
      color: "#fff",
      fontWeight: 600,
      textTransform: "uppercase",
      fontSize: "14px",
      padding: "20px 5px",
      position: "relative",
      cursor: "pointer",
      "&::before": {
        content: " ' '",
        position: "absolute",
        width: "0%",
        height: "2px",
        backgroundColor: "#fff",
        bottom: "0px",
        transition: "width 0.5s",
        left: "0px",
      },
      "&:hover::before": {
        width: "100%",
      },
      "@media(max-width:850px)": {
        display: "block",
        margin: "10px 0px",
        padding: "10px 5px",
      },
    },
    serviceItem: {
      display: "inline-block",
      textDecoration: "none",
      margin: "0px 5px",
      color: "#fff",
      fontWeight: 600,
      textTransform: "uppercase",
      fontSize: "14px",
      padding: "20px 5px",
      cursor: "pointer",
      "@media(max-width:850px)": {
        display: "block",
        margin: " 10px 0px",
        padding: "10px 5px",
      },
    },
    active: {
      position: "relative",
      "&::before": {
        content: " ' '",
        position: "absolute",
        width: "100%",
        height: "2px",
        backgroundColor: "#fff",
        bottom: "0px",
        transition: "width 0.5s",
        left: "0px",
      },
    },
    menuBar: {
      display: "none",
      "@media(max-width:850px)": {
        display: "block",
      },
    },
    menuBtn: {
      cursor: "pointer",
      backgroundColor: "transparent",
      border: "0px",
    },
    menuIcon: {
      fontSize: "1.6em",
      color: "#fff",
    },
    // fixedTopBoxShadow: {
    //     boxShadow: "1px 2px 4px #d5cece"
    // },
    navMenuBlock: {
      "@media(max-width:850px)": {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    navMenuResponsiveBlock: {
      "@media(max-width:850px)": {
        justifyContent: "space-between !important",
        alignItems: "center",
      },
    },
    dropDown: {
      position: "relative",
      "&:hover .dropdowns": {
        display: "block",
      },
    },
    dropDownContent: {
      display: "none",
      backgroundColor: "#347ab6",
      position: "absolute",
      width: "220px",
      listStyle: "none",
      padding: "0px",
      "@media(max-width:850px)": {
        position: "relative",
        width: "100%",
        padding: " 0px 16px",
      },
    },
    DropDownItem: {
      textDecoration: "none",
      padding: "10px 15px",
      color: "#fff",
      display: "inline-block",
      fontSize: "16px",
      borderBottom: "1px solid #7fb4e2",
      width: "100%",
      transition: "ease-in-out 0.5s",
      "&:hover": {
        backgroundColor: "#fff",
        color: "#000",
      },
    },
    arrowIcon: {
      fontSize: "14px !important",
    },
    deepDropDown: {
      position: "relative",
      "&:hover .deepDropDownContents": {
        display: "block",
      },
    },
    deepDropDownContent: {
      display: "none",
      position: "absolute",
      left: "200px",
      backgroundColor: "#347ab6",
      width: "200px",
      top: "0px",
      listStyle: "none",
      padding: "0px",
      zIndex: 200,
      "@media(max-width:850px)": {
        position: "relative",
        left: "0px",
      },
    },
  });

  const classes = Style();
  //

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 90);
    });
  }, []);

  const location = useLocation();

  const path = location.pathname;


  return (
    <>
      <div className={`${classes.container}`}>
        <div className={classes.navbar}>
          <div
            className={`${classes.navMenuBlock} ${scroll && classes.navMenuResponsiveBlock
              }`}
          >
            <div
              className={`${classes.logoSection} ${scroll && classes.logoSectionResponsive
                }`}
            >
              <Link onClick={() => window.scrollTo(0, 0)} to="/">
                <img src={logoImg} width={"100%"} alt="Logo" />
              </Link>
            </div>
            <div className={classes.menuBar}>
              <button className={classes.menuBtn} onClick={handleClose}>
                <Menu className={classes.menuIcon} />
              </button>
            </div>
          </div>
          <div className={classes.nav}>
            <ul className={classes.menu}>
              <li>
                <Link
                  to="/"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setshow(false);
                  }}
                  className={`${classes.item} ${path === "/" && classes.active
                    }`}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setshow(false);
                  }}
                  className={`${classes.item} ${path === "/about" && classes.active
                    }`}
                >
                  About
                </Link>
              </li>

              <li className={classes.dropDown}>
                <div className={`${classes.serviceItem} `}>
                  Service
                  <img
                    src={serviceArrow}
                    style={{
                      marginLeft: "5px",
                    }}
                    width="12px"
                    alt="serviceArrow"
                  />
                </div>
                <ul className={`${classes.dropDownContent} dropdowns `}>
                  <li className={classes.deepDropDown}>
                    <Link
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setshow(false);
                      }}
                      className={classes.DropDownItem}
                      to="/service/forex-pack"
                    >
                      Forex pack
                      {/* <ArrowForwardIos className={classes.arrowIcon} /> */}
                    </Link>
                    {/* <ul className={`${classes.deepDropDownContent} deepDropDownContents`}>
                                            <li>
                                                <Link className={classes.DropDownItem} to="/">
                                                    Basic forex pack
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className={classes.DropDownItem} to="/">
                                                    HNI forex pack
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className={classes.DropDownItem} to="/">
                                                    Customized forex pack
                                                </Link>
                                            </li>
                                        </ul> */}
                  </li>

                  <li className={classes.deepDropDown}>
                    <Link
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setshow(false);
                      }}
                      className={classes.DropDownItem}
                      to="/service/comex-pack"
                    >
                      Comex pack
                      {/* <ArrowForwardIos className={classes.arrowIcon} /> */}
                    </Link>
                    {/* <ul className={`${classes.deepDropDownContent} deepDropDownContents`}>
                                            <li>
                                                <Link className={classes.DropDownItem} to="/">
                                                    Basic comex pack
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className={classes.DropDownItem} to="/">
                                                    HNI comex pack
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className={classes.DropDownItem} to="/">
                                                    Customized comex pack
                                                </Link>
                                            </li>
                                        </ul> */}
                  </li>

                  <li className={classes.deepDropDown}>
                    <Link
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setshow(false);
                      }}
                      className={classes.DropDownItem}
                      to="/service/indices-pack"
                    >
                      Indicies pack
                      {/* <ArrowForwardIos className={classes.arrowIcon} /> */}
                    </Link>
                    {/* <ul className={`${classes.deepDropDownContent} deepDropDownContents`}>
                                            <li>
                                                <Link className={classes.DropDownItem} to="/">
                                                    Basic indicies pack
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className={classes.DropDownItem} to="/">
                                                    HNI indicies pack
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className={classes.DropDownItem} to="/">
                                                    Customized indicies pack
                                                </Link>
                                            </li>
                                        </ul> */}
                  </li>

                  <li className={classes.deepDropDown}>
                    <Link
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setshow(false);
                      }}
                      className={classes.DropDownItem}
                      to="/service/crypto-pack"
                    >
                      Crypto pack
                      {/* <ArrowForwardIos className={classes.arrowIcon} /> */}
                    </Link>
                    {/* <ul className={`${classes.deepDropDownContent} deepDropDownContents`}>
                                            <li>
                                                <Link className={classes.DropDownItem} to="/">
                                                    Basic crypto pack
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className={classes.DropDownItem} to="/">
                                                    HNI crypto pack
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className={classes.DropDownItem} to="/">
                                                    Customized crypto pack
                                                </Link>
                                            </li>
                                        </ul> */}
                  </li>
                  <li>
                    <Link
                      onClick={() => {
                        window.scrollTo(0, 0);
                        setshow(false);
                      }}
                      className={classes.DropDownItem}
                      to="/service/courses"
                      style={{ borderBottom: "0px" }}
                    >
                      Courses
                      {/* <ArrowForwardIos className={classes.arrowIcon} /> */}
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <a
                  href="https://blog.investmentbaron.in/"
                  onClick={() => setshow(false)}
                  className={`${classes.item} ${path === "/blog" && classes.active
                    }`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </li>
              <li>
                <Link
                  to="/contact-us"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setshow(false);
                  }}
                  className={`${classes.item} ${path === "/contact-us" && classes.active
                    }`}
                >
                  Contact us
                </Link>
              </li>
              {/* <li>
                                <Link to="/offer"
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                        setshow(false)
                                    }}
                                    className={`${classes.item} ${path === "/offer" && classes.active}`}
                                >
                                    Offer
                                </Link>
                            </li> */}
              <li>
                <Link
                  to="/career"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    setshow(false);
                  }}
                  className={`${classes.item} ${path === "/career" && classes.active
                    }`}
                >
                  Career
                </Link>
              </li>
              {/* <li>
                                <Link to="/news"
                                    onClick={() => {
                                        window.scrollTo(0, 0)
                                        setshow(false)
                                    }}
                                    className={`${classes.item} ${path === "/news" && classes.active}`}
                                >
                                    news
                                </Link>
                            </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TheNavbar;
