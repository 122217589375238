import TheHeader from "./TheHeader"
import TheFooter from "./TheFooter"
import TheContent from "./TheContent"

const TheLayout = (props: any) => {
    return (
        <>
            <TheHeader />
            <TheContent />
            <TheFooter />
        </>
    )
}

export default TheLayout
