import CareerForm from './CareerForm'
import arrowImg from "../../assest/img/list_icon.webp"
import careerpageStyle from "./CareerPageStyle";
import DynamicCareerBanner from './DynamicCareerBanner';
import banner from "../../assest/img/business_analyst_slider.webp"
import banner_moblie from "../../assest/img/business-analyst-mob-slider.webp"


const TeamLeader = () => {
    const classes = careerpageStyle()
    return (
        <>
            <DynamicCareerBanner
                img={banner}
                responsiveimg={banner_moblie}
                title="Current Openings - Team Leader"
            // paragraph="Home >> Career"
            />
            <div className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.row}>
                        {/* <div className={`${classes.col} ${classes.formCol}`}>
                            <h4 className={classes.formheading} >Apply For This Position</h4>
                            <CareerForm />
                        </div> */}
                        <div className={`${classes.col} ${classes.infoCol}`}>
                            <h4 className={classes.heading} >Position Description</h4>
                            <div className={classes.jobDescrictionSection}>

                                <h3 className={classes.jobTitle}>
                                    Job Title:
                                </h3>
                                <p className={classes.jobDescriction}>
                                    Team Leader
                                </p>
                                <h3 className={classes.jobTitle}>
                                    Reporting to:
                                </h3>
                                <p className={classes.jobDescriction}>
                                    Floor Manager
                                </p>

                            </div>
                            <div className={classes.accountabilitySection}>
                                <h4 className={classes.accountabilityTitle}>Accountability:</h4>
                                <p className={classes.accountabilityDescription} >Creating an inspiring team environment & Setting clear team goals to ensuring the assigned tasks meet within the required timelines.</p>
                            </div>
                            <div className={classes.menuListSection}>
                                <h4 className={classes.heading} >Roles and Responsibilities:</h4>
                                <ul className={classes.menu}>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Delegate tasks and set deadlines.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Monitor team performance and report on metrics.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Recognize high performance and reward accomplishments.
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Encourage creativity and risk-taking.
                                    </li>
                                </ul>
                            </div>

                            <div className={classes.menuListSection}>
                                <h4 className={classes.heading} >Required Competencies:</h4>
                                <ul className={classes.menu}>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Knowledge of FOREX & COMEX Research Market..
                                    </li>
                                    <li className={classes.item}>
                                        <img className={classes.arrowIconImg} src={arrowImg} alt="arrowIcon" />
                                        Minimum 1 Experience of Sales in Forex & Comex Market.
                                    </li>
                                </ul>
                            </div>

                            <div className={classes.menuListSection}>
                                <h4 className={classes.heading} >Required Qualifications</h4>
                                <p className={classes.requiredment}>Candidate Must be Graduate at least,Pursuing Candidate need not to apply.</p>
                                <h4 className={classes.ageCriteria}>
                                    <strong>Age Criteria:</strong> 18 – 35 Years
                                </h4>
                                <p className={classes.moreDetails}>For More Details Contact us at info@investmentbaron.in</p>
                            </div>

                            <div className={classes.contactForm}>
                                {/* <div className={classes.underLine}></div> */}
                                <CareerForm />
                            </div>
                            <div className={classes.underLine}></div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TeamLeader