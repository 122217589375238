import {
  Facebook,
  Instagram,
  Twitter,
  MailOutline,
  Telegram,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import YouTube from "@mui/icons-material/YouTube";
const Style = makeStyles({
  container: {
    background: "#3c403d",
    padding: "5px",
  },
  row: {
    width: "90%",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  col: {},
  menu: {
    padding: "0px",
    margin: "0px",
    listStyle: "none",
    display: "flex",
    alignItems: "center",
  },
  item: {
    color: "#fff",
    display: "inline-block",
    margin: "0px 0.5em",
    borderRadius: "50%",
  },
  icon: {
    verticalAlign: "middle",
    color: "#fff",
    fontSize: "20px !important",
  },

  btn: {
    color: "#3c403d !important",
    marginLeft: "10px !important",
    border: "1px solid #fff !important",
    backgroundColor: "#fff !important",
    padding: "2px 15px !important",
    fontWeight: "700 !important",
  },
  contact: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media(max-width:700px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  contactCol: {
    marginLeft: 10,
  },
  link: {
    paddingLeft: "5px",
    display: "inline-block",
    color: "white",
    fontSize: "12px",
    textDecoration: "none",
  },
  chatSection: {
    position: "fixed",
    bottom: "200px",
    right: "20px",
    zIndex: 1222,
    backgroundColor: "#5e87fc",
    borderRadius: "50%",
    cursor: "pointer",
    width: "50px",
    height: "50px",
    textAlign: "center",
    padding: "10px",
  },
  liveChatImg: {
    width: "100%",
    verticalAlign: "middle",
  },
  socialMediaMenu: {
    display: "flex",
  },
  socialMediaItem: {
    display: "inline-block",
    paddingRight: "10px",
  },
  linkicon: {
    fontSize: "1.1em !important",
    color: "#fff",
    verticalAlign: "middle",
  },
  circlelink: {
    borderRadius: "50%",
    width: "31px !important",
    height: "31px !important",
    border: "2px solid #fff",
    textAlign: "center",
    margin: "3px auto",
    paddingTop: "1px",
    "&:hover": {
      backgroundColor: "#3B5998",
      color: "#fff",
      border: "1px solid #3B5998",
      boxShadow: "inset 0px 0px 0px 3px #ffffff",
    },
  },
  facebookcolor: {
    "&:hover": {
      backgroundColor: "#3B5998",
      color: "#fff",
      border: "1px solid #3B5998",
      boxShadow: "inset 0px 0px 0px 3px #ffffff",
    },
  },
  twittercolor: {
    "&:hover": {
      backgroundColor: "#33ccff",
      color: "#fff",
      border: "1px solid #33ccff",
      boxShadow: "inset 0px 0px 0px 3px #ffffff",
    },
  },
  youtubecolor: {
    "&:hover": {
      backgroundColor: "red",
      color: "#fff",
      border: "1px solid red",
      boxShadow: "inset 0px 0px 0px 3px #ffffff",
    },
  },
  instagramcolor: {
    "&:hover": {
      backgroundColor: "#cd3b9e",
      color: "#fff",
      border: "1px solid #cd3b9e",
      boxShadow: "inset 0px 0px 0px 3px #ffffff",
    },
  },

  whatsappicon: {
    verticalAlign: "middle",
    fontSize: "22px !important",
    color: "#fff",
    "&:hover": {
      color: "#0088cc",
    },
  },
});

const TopHeader = () => {
  const classes = Style();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.row}>
          <div className={classes.col}>
            <ul className={`${classes.menu} ${classes.socialMediaMenu}`}>
              <li>
                <a
                  className={classes.socialMediaItem}
                  href="https://www.facebook.com/investmentbaron9/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className={`${classes.circlelink} ${classes.facebookcolor}`}
                  >
                    <Facebook className={classes.linkicon} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  className={classes.socialMediaItem}
                  href="https://twitter.com/investmentbaron"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className={`${classes.circlelink} ${classes.twittercolor}`}
                  >
                    <Twitter className={classes.linkicon} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  className={classes.socialMediaItem}
                  href=" https://www.youtube.com/channel/UC-94FwN44ZLhHQslvEHxT_Q"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className={`${classes.circlelink} ${classes.youtubecolor}`}
                  >
                    <YouTube className={classes.linkicon} />
                  </div>
                </a>
              </li>
              <li>
                <a
                  className={classes.socialMediaItem}
                  href=" https://www.instagram.com/investment_baron/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className={`${classes.circlelink} ${classes.instagramcolor}`}
                  >
                    <Instagram className={classes.linkicon} />
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div className={classes.col}>
            <div className={classes.contact}>
              {/* <div className={classes.contactCol}>
                <a
                  href="https://linktr.ee/investmentbaron?utm_source=linktree_profile_share"
                  className={classes.link}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <Telegram className={classes.whatsappicon} /> :
                  investmentbaron
                </a>
              </div> */}
              <div className={classes.contactCol}>
                <MailOutline className={classes.icon} />
                <a
                  href=" mailto:info@investment-baron.com"
                  className={classes.link}
                >
                  : info@investment-baron.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={classes.chatSection}>
                <img className={classes.liveChatImg} src={liveChatImg} alt="liveChat" />
            </div> */}
    </>
  );
};

export default TopHeader;
