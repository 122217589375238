import { ArrowForwardIos } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'

const style = makeStyles({
    container: {
        padding: "2em 0em",
        width: "90%",
        margin: "auto",
        "& h2": {
            fontSize: "2.3em",
            margin: "0px",
            paddingBottom: "10px",
            color: "#347ab6",
            fontWeight: 600,
            textAlign: "center",
        },
        "& h4": {
            margin: "0px",
            padding: "10px 0px",
            fontWeight: 600,
            fontSize: "1.5em",
        },
        "& ul": {
            listStyle: "none",
        },
        "& li": {
            color: "#5f5f5f",
            fontSize: "16px",
            padding: "3px 0px",
        },
        "& p": {
            margin: "0px",
            paddingBottom: "10px",
            fontSize: "14px",
            color: "#5f5f5f",
            fontWeight: 600,
        },
    },
    arrowIcon: {
        fontSize: "14px !important",
        marginRight: "5px",
        verticalAlign: "middle",
        display: "none",
        color: "#000"
    },
})

const RefundPolicy = () => {
    const classes = style()
    return (
        <>
            <div className={classes.container} >
                <h2>REFUND POLICY</h2>
                <p>We value our customer’s and are dedicated to providing the best services. By subscribing to our services and accepting and taking advantage of the free trial, you agree that there will be no refunds.</p>
                <h4>Refund policy:</h4>
                <p>We’re having the professional employers in our research team who are well experienced and trained. They are capable to study the market instantly thereby providing the beneficial signals, tips, recommendations, or calls. At INVESTMENT BARON, we give the signals, tips, recommendations, or calls through proper analysis about the market & thereafter recommend the trading strategy. Additionally, we do provide the free trials to ensure our clients with the type of services we provide to them. Thus, we do not have any cancellation or refund policy formatted.</p>
                <h4>Before subscribing with INVESTMENT BARON, please go through the following:</h4>
                <ul>
                    <li><ArrowForwardIos className={classes.arrowIcon} />Read the terms and condition page.</li>
                    <li><ArrowForwardIos className={classes.arrowIcon} />Go through the privacy policy of the company.</li>
                    <li><ArrowForwardIos className={classes.arrowIcon} />Have a look at the FAQ section.</li>
                    <li><ArrowForwardIos className={classes.arrowIcon} />Go through the about us page.</li>
                    <li><ArrowForwardIos className={classes.arrowIcon} />Analyze our performance through our track sheets.</li>
                    <li><ArrowForwardIos className={classes.arrowIcon} />Before making a decision for subscribing with us take a view of our site. Check all your credit cards and passwords are not disclosed to anyone and no one can pay from your account so that your membership remains secured.</li>
                </ul>
                <p>Always put an SL (stop loss) while trading in any call given by us, we at ‘INVESTMENT BARON’ never give any call without a stop loss, therefore trade with stop loss and in case if you don’t put SL (stop loss) the company would not be responsible for the loss.</p>
                <p>Thus, make sure you have gone through the disclaimer, privacy policy, terms and conditions and refund policy before proceeding for subscription or making payment for any of our services.</p>
            </div>
        </>
    )
}

export default RefundPolicy