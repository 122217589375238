import { makeStyles } from "@mui/styles";
import InputField from "./InputField";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import axios from "axios";

const initialValues = {
  name: "",
  email: "",
  mobile: "",
  position: "",
  eduction: "",
  attachments: null,
};

const validationSchema = Yup.object({
  name: Yup.string().required("This Field is Required"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("This Field is Required"),
  mobile: Yup.string()
    .matches(/^(\+91[\s]?)?[0]?(91)?\d{10}$/, "Enter a valid phone number")
    .required("This Field is Required"),
  position: Yup.string().required("This Field is Required"),
  eduction: Yup.string().required("This Field is Required"),
  attachments: Yup.mixed().required("This Field is Required"),
});

const Style = makeStyles({
  formlableTitle: {
    color: "#333",
    fontSize: "15px",
    fontWeight: 500,
  },
  inputRow: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "5px",
  },
  inputBlock: {
    padding: "10px 0px",
    width: "45%",
  },
  applyBtn: {
    color: "#fff",
    backgroundColor: "#347ab6",
    border: "0px",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "8px",
    cursor: "pointer",
    fontWeight: 500,
    marginTop: "3em",
    width: "100%",
  },
  uploadDocument: {
    fontSize: 16,
  },
});

const handleSubmit = async (values: any, resetForm: any) => {
  const liveURL = "https://api.investmentbaron.in/career-form-mail";

  const formData = new FormData();

  formData.append("name", values.name);
  formData.append("email", values.email);
  formData.append("mobile", values.mobile);
  formData.append("position", values.position);
  formData.append("eduction", values.eduction);
  formData.append("attachments", values.attachments);

  try {
    const res = await axios.post(liveURL, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (res.status === 200) {
      alert("your request is successfuly submitted. we contact you soon");
      resetForm();
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error.message);
  }
};

const CareerForm = () => {
  const classes = Style();
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div>
                <span className={classes.formlableTitle}>
                  Applicant Name (required)
                </span>
                <div className={classes.inputRow}>
                  <div className={classes.inputBlock}>
                    <InputField
                      name="name"
                      placeholder="Name"
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      values={values}
                    />
                  </div>
                  <div className={classes.inputBlock}>
                    <InputField
                      name="email"
                      placeholder="Email"
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      values={values}
                    />
                  </div>
                </div>

                <div className={classes.inputRow}>
                  <div className={classes.inputBlock}>
                    <InputField
                      name="mobile"
                      placeholder="Mobile Number"
                      required={true}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      values={values}
                    />
                  </div>
                  <div className={classes.inputBlock}>
                    <InputField
                      name="position"
                      placeholder="Apply for the Position"
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      values={values}
                    />
                  </div>
                </div>
                <div className={classes.inputRow}>
                  <div className={classes.inputBlock}>
                    <InputField
                      name="eduction"
                      placeholder="Education"
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      values={values}
                    />
                  </div>
                  <div className={classes.inputBlock}>
                    {/* <button>Browse...</button> */}
                    {/* <span style={{ marginLeft: "10px" }}>No file select</span> */}
                    <input
                      name="attachments"
                      onChange={(e: any) =>
                        setFieldValue("attachments", e.target.files[0])
                      }
                      type={"file"}
                      className={classes.uploadDocument}
                    />
                    <div>
                      {errors.attachments && touched.attachments && (
                        <div
                          style={{
                            fontSize: 12,
                            color: "#d32f2f",
                            fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                            marginTop: 5,
                            marginLeft: 10,
                          }}
                        >
                          {errors.attachments}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <button type="submit" className={classes.applyBtn}>
                  APPLY FOR THIS POSITION
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CareerForm;
