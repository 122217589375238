import { PayPalButtons } from "@paypal/react-paypal-js";
import axios from "axios";
import React, { useEffect, useState } from "react";

const handleApprove = async (order: any) => {
  const liveURL = "https://api.investmentbaron.in/paypal-send-mail";
  try {
    const res = await axios.post(liveURL, order);
    if (res.status === 200) {
      alert(
        `Thanks For Buy Our Pack. \nPlease Save Transaction ID For Future Reference\n ${order.id}`
      );
    } else {
      alert("Something went wrong");
    }
  } catch (error: any) {
    alert(error.message);
  }
};

interface IpaypalBtn {
  product: {
    description: string;
    price: string | number;
  };
  color?: "gold" | "blue" | "silver" | "white" | "black";
  shape?: "rect" | "pill";
  height?: number;
}

const PaypalBtn = (props: IpaypalBtn) => {
  const { product, color = "gold", shape = "pill", height = 48 } = props;

  const [purchaseUnit, setPurchaseUnit] = useState({
    description: product.description,
    amount: {
      value: product.price.toString(),
    },
  });

  useEffect(() => {
    setPurchaseUnit({
      description: product.description,
      amount: {
        value: product.price.toString(),
      },
    });
  }, [product.price, product.description]);

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <PayPalButtons
          style={{
            color: color,
            layout: "horizontal",
            height: height,
            tagline: false,
            shape: shape,
          }}
          createOrder={(data: any, actions: any) => {
            return actions.order.create({
              intent: "CAPTURE",
              purchase_units: [purchaseUnit],
            });
          }}
          onApprove={async (data: any, actions: any) => {
            const order = await actions.order.capture();
            handleApprove(order);
          }}
          onError={(err) => {
            console.log("PayPal Checkout onError", err);
          }}
          onCancel={() => {
            alert("You Cancel Your Order");
          }}
        />
      </div>
    </>
  );
};

export default PaypalBtn;
