import { makeStyles } from "@mui/styles"


const Style = makeStyles({
    container: {
        padding: "1em",
        width: "95%",
        margin: "auto",
    },
    heading: {
        margin: "0px",
        fontSize: "1.5em",
        textAlign: "center",
        color: '#347ab6',
        padding: "1.5em 0em",
    },
    description: {
        color: "#5f5f5f",
        fontWeight: 500,
        margin: "16px 0px",
    },
    termHeading: {
        fontSize: "1.3em",
        color: "#000",
        margin: "0px",
    },
    subHeading: {
        fontSize: "1.1em",
        margin: "0px",
        textTransform: "capitalize",
    }
})

const TermsAndCondition = () => {
    const classes = Style()
    return (
        <>
            <div className={classes.container}>
                <h1 className={classes.heading}>TERMS & CONDITIONS</h1>
                <p className={classes.description}>
                    Please read these terms and conditions carefully before using the site. By using the site or any part of it, you agree that you have read these terms and conditions and that you accept and agree to be bound by them.
                </p>
                <h3 className={classes.termHeading}>
                    Terms & Conditions:
                </h3>
                <p className={classes.description}>
                    By visiting our site you are agreeing to be bound by the following terms and conditions. We may change these terms and conditions as per our policies. Your continual use of www.INVESTMENT BARON.com means that you accept any modified terms and conditions that we come up with. Please re-visit the `Terms of Use’ link at our site from time to time to stay updated about any changes that we may introduce.
                </p>
                <h2 className={classes.subHeading}>
                    (1) Registration
                </h2>
                <p className={classes.description}>
                    By registering, you certify that all information you provide is consistent at present & in future. The INVESTMENT BARON reserves the right to deny you access to this website or any portion without any notice for the following reasons (a) immediately by INVESTMENT BARON for any unauthorized access or use by you (b) immediately by INVESTMENT BARON if you assign or transfer (or attempt the same) any rights granted to you under this Agreement; (c) immediately, if you violate any of the other terms and conditions of this User Agreement.
                </p>
                <h2 className={classes.subHeading}>
                    (2) License
                </h2>
                <p className={classes.description}>
                    INVESTMENT BARON hereby grants you a limited, non-exclusive, non-assignable and non-transferable license to access INVESTMENT BARON provided and conditioned upon your agreement that all such access and use shall be governed by all of the terms and conditions set forth in this ‘USER AGREEMENT’.
                </p>
                <h2 className={classes.subHeading}>
                    (3) Copyright & No Retransmission Of Information
                </h2>
                <p className={classes.description}>
                    The information & design contained in this site is the valuable and exclusive property of INVESTMENT BARON and nothing in this agreement shall be construed as transferring or assigning any such ownership rights to you or any other person. All signals, tips, recommendations, or calls information on our site is the proprietary and confidential property of INVESTMENT BARON and cannot be repeated for any reason outside our website. You may not rent, lease, sublicense, distribute, transfer, copy, reproduce, publicly display and publish any of the information received or accessed there from any other person or entity unless separately and specifically authorized in writing by INVESTMENT BARON prior to such use.
                </p>
                <p className={classes.description}>
                    You agree not to repeat or rebroadcast in any way any of the signals, tips, recommendations, or calls information made on our site for any reason whatsoever. You agree that if you do repeat or re-post any of INVESTMENT BARON signals, tips, recommendations, or calls by any means, you will be liable for any damages which are to be determined by the Indian court of Law. You may not resell, redistribute, broadcast or transfer the information or use the information in a searchable, machine-readable database unless separately and specifically authorized in writing by INVESTMENT BARON prior to such use.
                </p>
                <p className={classes.description}>
                    In addition to this, you may not remove, alter or obscure any copyright, legal or proprietary notices in or on any portions of our site without prior written authorization.
                </p>
                <h2 className={classes.subHeading}>
                    (4) Delays In Services
                </h2>
                <p className={classes.description}>
                    INVESTMENT BARON (including its and their directors or employees) won’t be liable for any loss directly or indirectly from delays or interruptions due to electronic or mechanical equipment failures, telephone interconnect problems, defects, weather, strikes, walkouts, fire, acts of God, riots, armed conflicts or any act of war. INVESTMENT BARON shall have no responsibility to provide you access to this site while any interruption occurs due to any such cause.
                </p>
                <h2 className={classes.subHeading}>
                    (5) Liability Disclaimer
                </h2>
                <p className={classes.description}>
                    The contents, information, software, products, features, and services published on this website may include inaccuracies or typographical errors. INVESTMENT BARON may make improvements and/or changes in this website at any time. This website may be temporarily unavailable from time to time due to required maintenance, telecommunications interruptions, or other disruptions.
                </p>
                <p className={classes.description}>
                    INVESTMENT BARON and its (owners, advertisers, affiliates, employees or any other associated entities, all collectively referred to as associated entities hereafter) shall not be liable to the user, member or any third party if INVESTMENT BARON exercise its rights to modify or discontinue any or all of the contents, information, software, products, features and services published on this website.
                </p>
                <p className={classes.description}>
                    INVESTMENT BARON creates no representations about the suitability of the contents, information, software, products, features, and services contained on this website for any purpose. All such things are provided “AS IS” without any warranty. INVESTMENT BARON hereby all warranties and conditions with regard to these contents, information, software, products, features, and services, including all implied warranties and conditions of merchantability, fitness for a particular purpose, title, non-infringement, and availability.
                </p>
                <h2 className={classes.subHeading}>
                    (6) Trading Discipline
                </h2>
                <p className={classes.description}>
                    INVESTMENT BARON strictly follows trading principle and stop-loss policy wherein the clients by default agree to not do/enter any trade without pre-informed/defined Stop-Loss.
                </p>
                <h2 className={classes.subHeading}>
                    (7) Refund & Cancellation Policy
                </h2>
                <p className={classes.description}>
                    INVESTMENT BARON offers a 3 days free trial evaluation to ensure that our products and services will meet your needs without the need to purchase; there will be ABSOLUTELY NO REFUNDS and CANCELLATIONS. Before deciding to subscribe to our services, please make sure to take our 3 days free trial, the evaluation version that we provide. We do not offer refunds on subscriptions that have already been taken. We do believe in our services and support and even give 3 days free trial that, without any exception, we have a NO REFUND POLICY.
                </p>
                <p className={classes.description}>
                    Bills/Invoice and other documents like KYC and Risk profile form would only be provided through electronic media, not in hard copy.
                </p>
                <p className={classes.description}>
                    For this reason, we strongly recommend that before payment, our visitors 1. Read all information about our products, services, and support given to our clients. 2. Evaluate the quality of our services by taking our 3 days free trial. 3. Read all About Us and our Technical Team. 4. Read the Help and Frequently Asked Questions (FAQ) sections. 5. Read our Terms of Use. 6. Read our Privacy Policy. 7. Read our Disclaimer.
                </p>
                <p className={classes.description}>
                    Do not allow children or other unauthorized family members or friends to access your credit cards or your account at the payment site to ensure that no one pays for a Membership without your permission.
                </p>
                <p className={classes.description}>
                    By making a payment for Membership to our site, you acknowledge that you have read and agree to the above No Refund and no cancellation Policy.
                </p>
                <h2 className={classes.subHeading}>
                    (8) Use Of Message Boards, Chat Rooms And Other Communication Forums
                </h2>
                <p className={classes.description}>
                    If this website contains message/bulletin boards, or other message or communication facilities (collectively, “Forums”), you agree to use the Forums only to send and receive messages and material that are proper and related to the particular Forum. By way of example, and not as a limitation, you agree that when using a Forum, you shall not do any of the following:
                </p>
                <p className={classes.description}>
                    Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of others.
                </p>
                <p className={classes.description}>
                    Publish, post, distribute or disseminate any defamatory, infringing, obscene, indecent or unlawful material or information.
                </p>
                <p className={classes.description}>
                    Upload files that contain software or other material protected by intellectual property laws (or by rights of privacy of publicity) unless you own or control the rights thereto or have received all necessary consents.
                    Upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another’s computer.
                </p>
                <p className={classes.description}>
                    Conduct or forward surveys, contests, or chain letters.
                </p>
                <p className={classes.description}>
                    Download any file posted by another user of a Forum that you know, or reasonably should know, cannot be legally distributed in such manner.
                </p>
                <h2 className={classes.subHeading}>
                    (9) Equipment And Operation
                </h2>
                <p className={classes.description}>
                    You shall provide and maintain all telephone/internet and other equipment necessary to access our site and the costs of any such equipment and/or telephone/internet connections or use, including any applicable taxes, shall be borne solely by you. You are responsible for operating your own equipment used to access our site.
                </p>
                <h2 className={classes.subHeading}>
                    (10) Information Disclaimer
                </h2>
                <p className={classes.description}>
                    It is acknowledged that the information provided through INVESTMENT BARON is compiled from sources, which are beyond the control. Though such information is recognized by the parties to be generally reliable, the parties acknowledge that inaccuracies may occur and INVESTMENT BARON does not warranty the consistency or suitability of the information.
                </p>
                <p className={classes.description}>
                    INVESTMENT BARON makes no endorsement of any particular security, market participant, or brokerage. Further, INVESTMENT BARON does not represent or warrant that it will meet your requirements or is suitable for your needs.
                </p>
                <p className={classes.description}>
                    In addition to this, INVESTMENT BARON disclaims all warranties, whether express, statutory to the users and/or any third party including any implied warranties of consistency, timeliness, completeness, merchantability, and fitness for a particular purpose as well as any warranties arising by virtue of custom of trade.
                </p>
                <p className={classes.description}>
                    Under this User Agreement, you assume all risk of errors including the transmission or translation of information. You assume full responsibility for implementing sufficient procedures and checks to satisfy your requirements for the consistency and suitability of INVESTMENT BARON including the information which you may require for the reconstruction of lost data or subsequent manipulations or analyses of the information under the User Agreement.
                </p>
                <h2 className={classes.subHeading}>
                    (11) Links To Third Party Sites
                </h2>
                <p className={classes.description}>
                    The links in this website will allow you to leave www.INVESTMENT BARON.com the linked sites are not under the control of our website. INVESTMENT BARON has not reviewed, nor approved these sites and is not responsible for the contents or omissions of any linked site or any links contained in a linked site. The inclusion of any linked site does not imply endorsement by our site.
                </p>
                <h2 className={classes.subHeading}>
                    (12) Conflicting Terms
                </h2>
                <p className={classes.description}>
                    If there is any conflict between this User Agreement and other documents, this User Agreement shall govern, whether such order or other documents is prior to or subsequent to this User Agreement, or is signed or acknowledged by any director, officer, employee, representative or agent of ‘INVESTMENT BARON’.
                </p>
                <h2 className={classes.subHeading}>
                    (13) Attorney’s Fees
                </h2>
                <p className={classes.description}>
                    If INVESTMENT BARON takes action (by itself or through its associate companies) to enforce any of the provisions of this User Agreement, including collection of any amounts due, We shall be entitled to recover from you and (you agree to pay), in addition to all sums to which it is entitled or any other relief, at law or in equity, reasonable and necessary attorney’s fees and any costs of any litigation.
                </p>
                <h2 className={classes.subHeading}>
                    (14) Entire Agreement
                </h2>
                <p className={classes.description}>
                    This User Agreement constitutes the entire agreement between the parties and no other agreement ‘written’ or ‘oral’ exists between you and us. By using the information on our website, you assume full responsibility for all gains and financial losses incurred by you.
                </p>
                <p className={classes.description}>
                    The Information on our site is not intended to provide tax, legal or investment advice which you should obtain from your professional advisor prior to making any investment of the type discussed in the Information. The Information does not constitute a solicitation by the information providers or from any other of the purchase or sale of securities.
                </p>
                <p className={classes.description}>
                    The service is provided “as is,” without warranty of any kind, either express or implied, including without limitation, any warranty for information, data, services, uninterrupted access, or products provided through or in connection with the service.
                </p>
                <p className={classes.description}>
                    Specifically, INVESTMENT BARON disclaims any and all warranties, including, but not limited to: (i) any warranties concerning the availability, consistency, usefulness, or content of information, products or services; and (ii) any warranties of title, warranty of non-infringement and warranties of merchantability or fitness for a particular purpose. This disclaimer of liability applies to any damages or injury caused by any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communication line failure, theft or destruction or unauthorized access to, alteration of, or use of record, whether for breach of contract, tort, negligence, or under any other cause of action.
                </p>
                <p className={classes.description}>
                    Neither INVESTMENT BARON nor any of its employees, content or service providers shall be liable to you for any direct, indirect, incidental, special or consequential damages arising out of use of service or inability to gain access to or use the service or out of any breach of any warranty. Because some countries do not allow the exclusion or limitation of liability for consequential or incidental damages, the above limitation may not apply to you. In such countries, the respective liability of INVESTMENT BARON, its employee’s content or service provider’s respective liability is limited to the amount provided under said law. Further, you agree and understand that all services provided are non-refundable and that you should carefully consider whether our services are able to meet your needs.
                </p>
                <h2 className={classes.subHeading}>
                    (15) Termination
                </h2>
                <p className={classes.description}>
                    This User Agreement and the license rights granted hereunder shall remain in full force and effect unless terminated or cancelled for any of the following reasons: (a) immediately by INVESTMENT BARON for any unauthorized access or use by you (b) immediately by INVESTMENT BARON if you assign or transfer (or attempt the same) any rights granted to you under this Agreement; (c) immediately, if you violate any of the other terms and conditions of this User Agreement. Termination or cancellation of this agreement shall not affect any right or relief to which INVESTMENT BARON may be entitled at law or in equity. Upon termination of this User Agreement, all rights granted to you will terminate and revert to our site.
                </p>
                <h2 className={classes.subHeading}>
                    (16) Dispute Settlement
                </h2>
                <p className={classes.description}>
                    All disputes, differences and questions of any nature which at any time arise between the parties to this agreement out of the construction of or concerning anything contained in or arising out of this agreement or as to the rights, duties or liabilities under it or the parties to it shall be referred to the sole arbitrator under the Arbitration and Conciliation Act, 1996. The sole arbitrator shall be appointed by the Managing Director of INVESTMENT BARON. The laws prevailing in India Shall alone apply to the Arbitration Proceedings between the parties.
                </p>
                <h2 className={classes.subHeading}>
                    (17) Jurisdiction
                </h2>
                <p className={classes.description}>
                    Both the Parties agree that all claims, differences and disputes, including any agreements, contracts and transactions made with reference to anything incidental thereto or in pursuance thereof or relating to their validity, construction, interpretation, fulfillment or the rights, obligations and liabilities of the parties thereto and including any question of whether such dealings, transactions MOA, or contracts have been entered into or not, shall be subject to the exclusive jurisdiction of the Courts of Indore, Madhya Pradesh, India only.
                </p>
            </div>

        </>
    )
}

export default TermsAndCondition