import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from '../routes';




const useStyles = makeStyles({
    content: {
    },
});



const TheContent = () => {
    const classes = useStyles();

    return (
        <>
            <main className={`${classes.content}`}>
                <Suspense
                    fallback={
                        <div style={{ position: "absolute", top: 0, right: 0, left: 0, height: "100vh", display: "flex", alignItems: "center", justifyContent: "center", background: "#fff", zIndex: 5000 }}>
                            <CircularProgress />
                        </div>
                    }
                >
                    <Switch>
                        {routes.map((route, idx) => {
                            return (
                                route.component && (
                                    <Route
                                        key={idx}
                                        path={route.path}
                                        exact
                                        render={(props) => (
                                            <route.component />
                                        )}
                                    />
                                )
                            );
                        })}
                        <Redirect from="/" to="/dashboard" />
                    </Switch>
                </Suspense>
            </main>

        </>
    )
}

export default TheContent
